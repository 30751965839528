import React from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const environmentalProtection = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Ochrona środowiska"
      lang={lang}
      translationEN="/en/environmental-protection/"
      translationDE="/de/umweltschutz/"
    >
      <SmallBanner title="Ochrona środowiska" lang={lang} />
      <section className="text-section text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Firma przyjazna
                <br />
                dla środowiska naturalnego
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                Firma PalettenWerk realizuje działania zmierzające do
                racjonalnego i bezpiecznego oddziaływania na środowisko
                naturalne. Polityka ekologiczna PalattenWerk obejmuje efektywne
                i oszczędne gospodarowanie surowcami i energią oraz ograniczenie
                do minimum negatywnego wpływu procesów produkcyjnych na
                środowisko.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Racjonalne
                <br />
                gospodarowanie surowcami
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                Palety drewniane PalettenWerk są produktem w pełni ekologicznym,
                trwałym i nadającym się do wielokrotnego użycia. Z palet nie
                nadających się do naprawy wytwarzane są inne produkty, takie jak
                pelet i trociny. Drewno używane do produkcji palet pochodzi
                wyłącznie z cięć pielęgnacyjnych oraz z odpadów drewna
                tartacznego.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Oszczędność
                <br />i odzysk energii
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                Firma PalettenWerk jest autorem licznych innowacji
                technologicznych w zakresie oszczędności i odzysku energii. W
                procesie produkcji wykorzystywane są piece opalane trocinami i
                korą, a ciepło powstałe w procesie spalania, a także w procesie
                suszenia palet, jest częściowo odzyskiwane i ponownie
                wprowadzane do obiegu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Etyczne pozyskiwanie
                <br />
                surowców
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
                Produkty PalettenWerk wytwarzane są wyłącznie z surowca
                pozyskanego z terenów leśnych zagospodarowanych w sposób
                zrównoważony. Powstają z poszanowaniem standardów ekologicznych,
                społecznych oraz etycznych. Potwierdzeniem spełnienia tych norm
                jest uzyskany w 2015 r. certyfikat PEFC.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default environmentalProtection;
